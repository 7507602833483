import React from "react";
export const bmnotFound = (value, lang) => {
    if (lang === "en") {
      return {
        foundText: "Here's what we've found for you",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>No results found</h5>
            <div>
              Sorry! We couldn't find&nbsp;<p> {value}</p>.
            </div>
            <div>
              Try a different search, or browse our <a href="/">Help Topics</a>{" "}
              instead.
            </div>
          </div>
        )
      };
    } else if (lang === "de") {
      return {
        foundText: "Das haben wir für Sie gefunden",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>keine Ergebnisse gefunden</h5>
            <div>
              Es tut uns leid! Wir konnten nicht finden &nbsp;<p>{value}</p>.
            </div>
            <div>
              Probieren Sie eine andere Suche aus oder durchsuchen Sie unsere{" "}
              <a href="/de">Hilfethemen</a> stattdessen.
            </div>
          </div>
        )
      };
    } else if (lang === "es") {
      return {
        foundText: "Esto es lo que hemos encontrado para ti.",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>No se han encontrado resultados</h5>
            <div>
              ¡Lo siento! No pudimos encontrar &nbsp;<p>{value}</p>.
            </div>
            <div>
              Pruebe una búsqueda diferente o navegue por nuestra{" "}
              <a href="/es">Temas de ayuda</a> en lugar.
            </div>
          </div>
        )
      };
    } else if (lang === "ru") {
      return {
        foundText: "Вот что мы нашли для вас",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>результаты не найдены</h5>
            <div>
              Сожалею! Мы не смогли найти &nbsp;<p>{value}</p>.
            </div>
            <div>
              Попробуйте другой поиск или просмотрите наши{" "}
              <a href="/ru">Разделы помощи</a> вместо.
            </div>
          </div>
        )
      };
    } else if (lang === "pt") {
      return {
        foundText: "Aqui está o que encontramos para você",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Nenhum resultado encontrado</h5>
            <div>
              Desculpe! Não conseguimos encontrar &nbsp;<p>{value}</p>.
            </div>
            <div>
              Tente uma pesquisa diferente ou navegue em nosso{" "}
              <a href="/pt">Tópicos de ajuda</a> em vez de.
            </div>
          </div>
        )
      };
    } else if (lang === "ja") {
      return {
        foundText: "私たちがあなたのために見つけたものは次のとおりです",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>結果が見つかりません</h5>
            <div>
              ごめんなさい！見つかりませんでした &nbsp;<p>{value}</p>.
            </div>
            <div>
              別の検索を試すか、 <a href="/ja">ヘルプトピックス</a> 代わりに。
            </div>
          </div>
        )
      };
    } else if (lang === "fr") {
      return {
        foundText: "Voici ce que nous avons trouvé pour vous",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Aucun résultat trouvé</h5>
            <div>
              Pardon! Nous n'avons pas pu trouver &nbsp;<p>{value}</p>.
            </div>
            <div>
              Essayez une autre recherche ou parcourez notre{" "}
              <a href="/fr">Sujets d'aide</a> au lieu.
            </div>
          </div>
        )
      };
    } else if (lang === "it") {
      return {
        foundText: "Ecco cosa abbiamo trovato per te",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>nessun risultato trovato</h5>
            <div>
              Spiacente! Non siamo riusciti a trovare &nbsp;<p>{value}</p>.
            </div>
            <div>
              Prova una ricerca diversa o sfoglia il nostro{" "}
              <a href="/it">Argomenti di aiuto</a> anziché.
            </div>
          </div>
        )
      };
    } else if (lang === "cs") {
      return {
        foundText: "Zde je to, co jsme pro vás našli",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Nebyly nalezeny žádné výsledky</h5>
            <div>
              Promiňte! Nemohli jsme najít &nbsp;<p>{value}</p>.
            </div>
            <div>
              Zkuste jiné vyhledávání nebo procházejte naše{" "}
              <a href="/cs">Témata nápovědy</a> namísto.
            </div>
          </div>
        )
      };
    } else if (lang === "el") {
      return {
        foundText: "Εδώ βρήκαμε για εσάς",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Δεν βρέθηκαν αποτελέσματα</h5>
            <div>
              Συγνώμη! Δεν μπορούσαμε να βρούμε &nbsp;<p>{value}</p>.
            </div>
            <div>
              Δοκιμάστε μια διαφορετική αναζήτηση ή περιηγηθείτε στη σελίδα μας{" "}
              <a href="/el">Θέματα βοήθειας</a> αντι αυτου.
            </div>
          </div>
        )
      };
    } else if (lang === "fi") {
      return {
        foundText: "Tässä olemme löytäneet sinulle",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Ei tuloksia</h5>
            <div>
              Anteeksi! Emme löytäneet &nbsp;<p>{value}</p>.
            </div>
            <div>
              Kokeile toista hakua tai selaa meidän <a href="/fi">Ohjeaiheita</a>{" "}
              sen sijaan.
            </div>
          </div>
        )
      };
    } else if (lang === "hi") {
      return {
        foundText: "यहां हमने जो पाया है वह हमने पाया है",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>कोई परिणाम नहीं मिला</h5>
            <div>
              माफ़ करना! हम नहीं मिला &nbsp;<p>{value}</p>.
            </div>
            <div>
              एक अलग खोज का प्रयास करें, या हमारे ब्राउज़ करें{" "}
              <a href="/hi">सहायता के विषय</a> बजाय।
            </div>
          </div>
        )
      };
    } else if (lang === "hr") {
      return {
        foundText: "Evo što smo pronašli za vas",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Nema rezultata</h5>
            <div>
              Oprosti! Nismo mogli pronaći &nbsp;<p>{value}</p>.
            </div>
            <div>
              Pokušajte s drugim pretraživanjem ili pregledajte naše{" "}
              <a href="/hr">Teme pomoći</a> umjesto toga.
            </div>
          </div>
        )
      };
    } else if (lang === "hu") {
      return {
        foundText: "Íme, amit találtunk az Ön számára",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Nincs találat</h5>
            <div>
              Sajnálom! Nem találtuk &nbsp;<p>{value}</p>.
            </div>
            <div>
              Próbálkozzon másik kereséssel, vagy böngésszen a mi weboldalunkon{" "}
              <a href="/hu">Segítő témák</a> helyette.
            </div>
          </div>
        )
      };
    } else if (lang === "id") {
      return {
        foundText: "Inilah yang kami temukan untuk Anda",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Tidak ada hasil yang ditemukan</h5>
            <div>
              Maaf! Kami tidak dapat menemukan &nbsp;<p>{value}</p>.
            </div>
            <div>
              Coba pencarian lain, atau telusuri{" "}
              <a href="/id">Topik-Topik Bantuan</a> sebagai gantinya.
            </div>
          </div>
        )
      };
    } else if (lang === "ko") {
      return {
        foundText: "우리가 당신을 위해 발견 한 것이 있습니다.",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>검색 결과가 없습니다</h5>
            <div>
              죄송합니다! 우리는 찾을 수 없습니다 &nbsp;<p>{value}</p>.
            </div>
            <div>
              다른 검색을 시도하거나 <a href="/ko">도움말 항목</a> 대신.
            </div>
          </div>
        )
      };
    } else if (lang === "nb") {
      return {
        foundText: "Her er hva vi har funnet for deg",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Ingen resultater</h5>
            <div>
              Beklager! Vi fant ikke &nbsp;<p>{value}</p>.
            </div>
            <div>
              Prøv et annet søk, eller bla gjennom <a href="/nb">Hjelpemner</a> i
              stedet.
            </div>
          </div>
        )
      };
    } else if (lang === "ar") {
      return {
        foundText: "هذا ما وجدناه لك",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>لم يتم العثور على نتائج</h5>
            <div>
              آسف! لم نتمكن من العثور عليها &nbsp;<p>{value}</p>.
            </div>
            <div>
              جرّب بحثًا مختلفًا ، أو تصفح <a href="/ar">مواضيع التعليمات</a> في
              حين أن.
            </div>
          </div>
        )
      };
    } else if (lang === "he") {
      return {
        foundText: "הנה מה שמצאנו עבורך",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>לא נמצאו תוצאות</h5>
            <div>
              מצטער! לא מצאנו &nbsp;<p>{value}</p>.
            </div>
            <div>
              נסה חיפוש אחר, או עיין בחיפוש שלנו <a href="/he">נושאי עזרה</a>{" "}
              במקום זאת.
            </div>
          </div>
        )
      };
    } else if (lang === "nl") {
      return {
        foundText: "Dit hebben we voor je gevonden",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>geen resultaten gevonden</h5>
            <div>
              Sorry! We konden het niet vinden &nbsp;<p>{value}</p>.
            </div>
            <div>
              Probeer een andere zoekopdracht, of blader door onze{" "}
              <a href="/nl">Hulponderwerpen</a> in plaats daarvan.
            </div>
          </div>
        )
      };
    } else if (lang === "pl") {
      return {
        foundText: "Oto, co dla Ciebie znaleźliśmy",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Nie znaleziono wyników</h5>
            <div>
              Przepraszam! Nie mogliśmy znaleźć &nbsp;<p>{value}</p>.
            </div>
            <div>
              Spróbuj innego wyszukiwania lub przejrzyj nasze{" "}
              <a href="/pl">Tematy pomocy</a> zamiast.
            </div>
          </div>
        )
      };
    } else if (lang === "tr") {
      return {
        foundText: "İşte sizin için bulduklarımız",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>Sonuç bulunamadı</h5>
            <div>
              Afedersiniz! Bulamadık &nbsp;<p>{value}</p>.
            </div>
            <div>
              Farklı bir arama deneyin veya <a href="/tr">Yardım konuları</a>{" "}
              yerine.
            </div>
          </div>
        )
      };
    } else if (lang === "zh") {
      return {
        foundText: "这就是我们为您找到的",
        notFoundText: (
          <div>
            <img src="/assets/search-illustration.svg" alt="search" />
            <h5>未找到结果</h5>
            <div>
              抱歉!我们找不到 &nbsp;<p>{value}</p>.
            </div>
            <div>
              尝试其他搜索，或浏览我们的 <a href="/zh">帮助主题</a> 代替。
            </div>
          </div>
        )
      };
    }
  };