import React from "react";
import { Location } from "@reach/router";
import Header from "../components/header";
import { ListGroup, ListGroupItem, Container } from "reactstrap";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import { navigate } from "gatsby";
import SiteConfig from "../../config/SiteConfig.json";
import FadeLoader from "react-spinners/FadeLoader";
import { bmnotFound } from '../../utils/bm-search'


class SearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      value: "",
      foundText: "",
      notFoundText: ""
    };
  }

  UNSAFE_componentWillMount() {
    let url = this.props.location.search;
    let value = url.split("?");
    let lang = url.slice(-2);
    value = value[1];
    value = decodeURIComponent(value);
    this.setState({ lang, value });

  }

//   tynotFound = (value, lang) => {
//     if (lang === "en") {
//       return {
//         foundText: "Here's what we've found for you",
//         notFoundText: "Sorry, no posts were found."
//       };
//     } else if (lang === "de") {
//       return {
//         foundText: "Das haben wir für Sie gefunden",
//         notFoundText: "Entschuldigung, es wurden keine Posts gefunden."
//       };
//     } else if (lang === "es") {
//       return {
//         foundText: "Esto es lo que hemos encontrado para ti.",
//         notFoundText: "Lo sentimos, no se encontraron publicaciones."
//       };
//     } else if (lang === "ru") {
//       return {
//         foundText: "Вот что мы нашли для вас",
//         notFoundText: "Извините, не найдено."
//       };
//     } else if (lang === "pt") {
//       return {
//         foundText: "Aqui está o que encontramos para você",
//         notFoundText: "Desculpe, nenhum post foi encontrado."
//       };
//     } else if (lang === "ja") {
//       return {
//         foundText: "私たちがあなたのために見つけたものは次のとおりです",
//         notFoundText: "申し訳ありませんが、投稿は見つかりませんでした。"
//       };
//     } else if (lang === "fr") {
//       return {
//         foundText: "Voici ce que nous avons trouvé pour vous",
//         notFoundText: "Désolé, aucun article n'a été trouvé."
//       };
//     } else if (lang === "it") {
//       return {
//         foundText: "Ecco cosa abbiamo trovato per te",
//         notFoundText: "Spiacente, nessun post è stato trovato."
//       };
//     } else if (lang === "cs") {
//       return {
//         foundText: "Zde je to, co jsme pro vás našli",
//         notFoundText: "Je nám líto, nebyly nalezeny žádné příspěvky."
//       };
//     } else if (lang === "el") {
//       return {
//         foundText: "Εδώ βρήκαμε για εσάς",
//         notFoundText: "Λυπούμαστε, δεν βρέθηκαν αναρτήσεις."
//       };
//     } else if (lang === "fi") {
//       return {
//         foundText: "Tässä olemme löytäneet sinulle",
//         notFoundText: "Valitettavasti mitään viestejä ei löytynyt."
//       };
//     } else if (lang === "hi") {
//       return {
//         foundText: "यहां हमने जो पाया है वह हमने पाया है",
//         notFoundText: "क्षमा करें, कोई पोस्ट नहीं मिलीं।"
//       };
//     } else if (lang === "hr") {
//       return {
//         foundText: "Evo što smo pronašli za vas",
//         notFoundText: "Nažalost, nijedan post nije pronađen."
//       };
//     } else if (lang === "hu") {
//       return {
//         foundText: "Íme, amit találtunk az Ön számára",
//         notFoundText: "Sajnáljuk, nincsenek bejegyzések."
//       };
//     } else if (lang === "id") {
//       return {
//         foundText: "Inilah yang kami temukan untuk Anda",
//         notFoundText: "Maaf, tidak ada pos yang ditemukan."
//       };
//     } else if (lang === "ko") {
//       return {
//         foundText: "우리가 당신을 위해 발견 한 것이 있습니다.",
//         notFoundText: "죄송합니다. 게시물을 찾을 수 없습니다."
//       };
//     } else if (lang === "nb") {
//       return {
//         foundText: "Her er hva vi har funnet for deg",
//         notFoundText: "Beklager, ingen innlegg ble funnet."
//       };
//     }
//   };

  componentDidMount() {
    let value = this.state.value;
    let lang = this.state.lang;
    if((SiteConfig.brand === "BlueMail" || SiteConfig.brand === "BlueMail+") && value && lang){
        this.setState({notFoundText: bmnotFound(value, lang).notFoundText, foundText: bmnotFound(value, lang).foundText})
    }
    fetch(`${SiteConfig.url}/searchapi/${value}?lang=${lang}`)
      .then(results => results.json())
      .then(data => {
        this.setState({ data, loading: false });
        console.log(
          "search fetching: ",
          `${SiteConfig.url}/searchapi/${value}?lang=${lang}`,
          " \ndata: ",
          data
        );
      })
      .catch(err => {
        this.setState({ data: "", loading: false });
        console.log("Error on searchapi", err);
      });
  }

  render() {
    return (
      <Location>
        {({ location }) => (
          <Layout className="page-content">
            <Seo
              postURL="/search"
              postTitle={`Search - ${SiteConfig.seo.siteName}`}
            />
            <Header lang={this.state.lang} path={this.props.location.search} />
            <Container style={{ padding: "32px 0" }}>
              {!this.state.loading && this.state.data.length > 0 ? (
                <div>
                  <h5 className="pb-4 search-title">{this.state.foundText}</h5>
                  <ListGroup className="text-left">
                    {this.state.data.map(post => {
                      return (
                        <ListGroupItem
                          key={post.url}
                          className="search-page-list-item"
                          onClick={() => navigate(post.url)}
                        >
                          <div className="h6 pl-5">{post.subject}</div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </div>
              ) : this.state.loading ? (
                <div className="loading-spinner">
                  <FadeLoader
                    sizeUnit={"px"}
                    size={250}
                    color={"grey"}
                    loading={this.state.loading}
                  />
                </div>
              ) : (
                <div
                  className={
                    SiteConfig.brand === "BlueMail" || SiteConfig.brand === "BlueMail+" ? "bm-not-found-ui" : ""
                  }
                >
                  <h2>{this.state.notFoundText}</h2>
                </div>
              )}
            </Container>
          </Layout>
        )}
      </Location>
    );
  }
}

export default SearchPage;
